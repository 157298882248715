.main-header {
  background-color: #255980;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.main-title {
  letter-spacing: 4px;
  font-weight: 700;
  font-size: calc(15px + 7vmin);
  text-shadow: 3px 3px 3px rgba(0,0,0,0.1);
  padding: 0 10px;
}

.main-title::after {
  content: "";
  display: block;
  width: 90%;
  height: 1px;
  margin: 20px auto;
  background: #fff;
}

.main-subtitle {
  font-size: calc(10px + 5vmin);
  letter-spacing: 4px;
  font-weight: 300;
  text-shadow: 3px 3px 3px rgba(0,0,0,0.1);
  padding: 0 10px;
}

.main-links {
  display: flex;
  justify-content: space-between;
  width: calc(90px + 13vmin);
  margin-top: 15px;
}

.main-links img {
  width: calc(30px + 5vmin);
}